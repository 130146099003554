import React from "react";

const Svg = ({ name, fill, className }) => {
  const SVGs = {
    check: (
      <svg
        width="21"
        height="15"
        viewBox="0 0 21 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M2.7248 5.6463L0.957031 7.41406L8.38165 14.8387L20.756 2.46432L18.9883 0.696548L8.38165 11.3031L2.7248 5.6463Z"
          fill={fill}
        />
      </svg>
    ),
    rightArrow: (
        <svg 
        width="16" 
        height="14" 
        viewBox="0 0 16 14" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        >
            <path 
            d="M6.5 12.5L11.5 8H0V6H11.5L6.5 1.5L8 0L15.5 7L8 14L6.5 12.5Z" 
            fill={fill}
            />
        </svg>

    ),
  };

  if (!SVGs[name]) {
    console.error(`O SVG com nome "${name}" não foi encontrado.`);
    return null;
  }

  return SVGs[name];
};

export default Svg;
