import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import About from "../components/about/About";
import Mission from "../components/about/Mission";
import Vision from "../components/about/Vision";
import Values from "../components/about/Values";
import Whatsapp from "../components/util/Whatsapp";
const AboutUs = () => {
    return (
        <>
            <Header />
            <Whatsapp />
            <About />
            <Mission />
            <Vision />
            <Values />
            <Footer />
        </>
    );
};

export default AboutUs;
