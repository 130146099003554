import React from "react";
import { Link } from "react-router-dom";
import responsive from "../../assets/responsive.png";

const Mission = () => {
  return (
    <>
      <div className="text-black w-3/4 mx-auto">
        <div className="xl:py-32 py-16">
          <p className="xl:text-6xl text-4xl font-bold">Missão</p>
          <p className="text-2xl mt-12 text-justify">Nossa missão na Hawx Soluções é transformar ideias em realidade digital por meio de soluções tecnológicas inovadoras e sob medida. Estamos empenhados em entender profundamente as necessidades de nossos clientes e fornecer produtos e serviços de alta qualidade que impulsionem seus negócios para o sucesso. Buscamos constantemente ultrapassar fronteiras, aprender e evoluir, criando um impacto positivo duradouro no mundo digital.</p>
        </div>
      </div>
    </>
  );
};

export default Mission;
