import React from "react";
import eduardomeneghel from "../../assets/eduardomeneghel.png";
import logo from "../../assets/logo.png";

const About = () => {
  return (
    <>
      <div className="bg-gray-50">
        <div className="text-black w-3/4 mx-auto">
          <div className="xl:py-32 py-16">
            <p className="xl:text-6xl text-4xl font-bold">Sobre Nós</p>
            <div className="grid justify-items-center">
              <div className="mt-12 grid justify-items-center">
                <img src={eduardomeneghel} className="rounded-full md:w-1/2 xl:1/4 w-full shadow-2xl"/>
              </div>
              <div className="w-full mt-16">
                <p className="text-2xl mt-12 text-justify">Sou Eduardo, formado em Sistemas de Informação e orgulhosamente fundador da Hawx Soluções. Com um profundo interesse em empreendedorismo desde o início da minha carreira, minha empresa representa o resultado desse desejo de inovação e excelência em soluções tecnológicas. Estamos comprometidos em oferecer serviços de qualidade e soluções personalizadas para atender às necessidades de nossos clientes.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
