import React from "react";
import responsive from "../assets/responsive.png";

const Budget = () => {
  return (
    <>
      <div className="bg-pattern xl:pb-32 rounded-b-3xl">
        <div className="text-white w-3/4 mx-auto bg-cover bg-center">
          <div className="flex flex-col xl:flex-row xl:pt-48 py-16 mb-4">
            <div className="xl2:w-1/2 xl:w-10/12 z-10 align-center">
              <img src={responsive} alt="vizualização de layout" className="h-auto mr-auto animate__animated animate__fadeInUp animate__delay-1s" />
            </div>
            <div className="xl:w-1/2 w-full z-10">
              <p className="xl:text-7xl text-5xl font-medium">Peça já o seu orçamento</p>
              <p className="text-2xl mt-12 mb-9 text-justify">Oferecemos soluções personalizadas e de alta qualidade para atender às suas necessidades com excelência.</p>
              <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank" className="bg-green-500 text-white py-6 px-8 rounded-2xl hover:bg-green-600 transition mt-8 w-full xl:w-2/3 block text-xl text-center">
                Peça seu orçamento
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Budget;
