import React from "react";

const Values = () => {
  return (
    <>
      <div className="text-black w-3/4 mx-auto xl:py-32 py-16">
        <p className="xl:text-6xl text-4xl font-bold">Valores</p>
        <div className="text-2xl">
          <p className="font-bold text-2xl mt-12">Qualidade:</p>
          <p className="text-justify">Nossa prioridade é entregar produtos e serviços de alta qualidade, sempre superando as expectativas.</p>
          <p className="font-bold text-2xl mt-12">Resolução de Problemas:</p>
          <p className="text-justify">Estamos comprometidos em enfrentar e resolver desafios de forma eficaz e responsável.</p>
          <p className="font-bold text-2xl mt-12">Honestidade e Integridade:</p>
          <p className="text-justify">Agimos com sinceridade e integridade em todas as nossas interações.</p>
          <p className="font-bold text-2xl mt-12">Aprendizado Contínuo:</p>
          <p className="text-justify">Estamos em constante evolução, sempre buscando melhorias.</p>
          <p className="font-bold text-2xl mt-12">Impacto Positivo:</p>
          <p className="text-justify">Buscamos deixar um legado positivo no mundo digital e na comunidade.</p>
        </div>
      </div>
    </>
  );
};

export default Values;
