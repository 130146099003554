import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Whatsapp from "../components/util/Whatsapp";

const NotFound = () => {
    return (
        <>
            <div class="flex flex-col h-screen text-center">
                <Header />
                <Whatsapp />
                <div class="m-auto">
                    <h1 class="text-6xl font-bold text-main">404</h1>
                    <h1 class="text-4xl font-bold text-secondary">Página não encontrada</h1>
                    <p class="text-secondary">Ops! A página que você procura não existe.</p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default NotFound;