import React, { useState } from 'react';
import imagem2 from "../../assets/2.png";

const Logotipo = () => {
  return (
    <>
      <div className="flex flex-col-reverse xl:flex-row xl:pt-24 pb-12 mb-4">
        <div className="flex xl2:w-1/2 xl:w-10/12 z-10 xl:block justify-center xl:justify-end animation-image">
          <img src={imagem2} />
        </div>
        <div className="xl:w-1/2 z-10">
          <p className="text-xl font-medium mb-4 text-green-800">Logotipo</p>
          <p className="text-3xl xl:text-6xl mb-10 font-medium">De um visual para sua marca</p>
          <p className="text-2xl mt-12 mb-16">Logo em alta qualidade, com a essência da sua marca e do seu trabalho.</p>
        </div>
      </div>
    </>
  );
};

export default Logotipo;
