import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './css/index.css';
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import NotFound from "./pages/NotFound";

createRoot(document.getElementById("root")).render(
  <Router>
    <Routes>

      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/sobre-nos" element={<AboutUs />} />
      <Route path="*" element={<NotFound />} />

    </Routes>
  </Router>
);