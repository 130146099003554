import React from "react";

const Vision = () => {
  return (
    <>
      <div className="bg-gray-50">
        <div className="text-black w-3/4 mx-auto">
          <div className="xl:py-32 py-16">
            <p className="xl:text-6xl text-4xl font-bold">Visão</p>
            <p className="text-2xl mt-12 text-justify">Nossa visão na Hawx Soluções é nos tornarmos líderes reconhecidos no setor de tecnologia, sendo a primeira escolha para empresas que buscam soluções digitais inovadoras e confiáveis. Queremos expandir nossa presença globalmente, mantendo nosso compromisso com a excelência, a criatividade e a satisfação do cliente.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vision;
