import React, { useState } from "react";
import logo from '../assets/logo.png';

const Header = () => {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="z-50 border-b border-gray-200">
      <nav className="w-11/12 mx-auto py-6 flex justify-between items-center">
        <div>
          <a href={"../"} className="inline-block">
            <img
              src={logo}
              alt="Logo"
              className="w-32 xl:w-44"
            />
          </a>
        </div>
        <div className="md:hidden relative">
          <button
            className=" hover:text-gray-600 focus:outline-none p-2"
            onClick={toggleMobileMenu}
          >
            <i className="fa-solid fa-bars  text-xl"></i>
          </button>
        </div>
        <ul className={`md:flex space-x-4 hidden md:block items-center`}>
          <li className="pr-4">
            <a href={"../"} className=" hover:text-gray-600 font-semibold">
                Início
            </a>
          </li>
          <li className="pr-4">
            <a href= {"sobre-nos"} className="hover:text-gray-600 font-semibold">
                Sobre Nós
            </a>
          </li>
          <li className="pr-4">
            <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank" className="text-white px-6 py-4 rounded-full font-bold bg-green-500 hover:bg-green-600 transition">Contato</a>
          </li>
        </ul>
      </nav>
      {isMobileMenuOpen && (
        <ul className="absolute top-26 right-0 bg-white z-20 mobile-menu w-48 rounded-b-xl flex flex-wrap justify-center">
          <li>
            <a href={"../"} className=" hover:text-gray-600 block p-4">Início</a>
          </li>
          <li className="mb-4">
            <a href={"sobre-nos"} className=" hover:text-gray-600 block p-4">Sobre Nós</a>
          </li>
          <li className="mb-3.5">
            <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank" className="text-white px-6 py-2 rounded-full font-bold bg-green-500 hover:bg-green-600 transition">Contato</a>
          </li>
        </ul>
      )}
    </header>
  );
};

export default Header;
